import React, {Suspense, useMemo, useRef, useCallback, useEffect, useState, lazy} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import NotFound from "../not-found/not-found";
import Loading from "../loading/loading";
import useModifier from "../../hooks/use-modifier";
import {detect} from 'detect-browser';
import {AppContext} from "./context";

const HomePage = lazy(() => import('../../pages/home'));
const MainStage = lazy(() => import('../../pages/main-stage'));
const InfoCenter = lazy(() => import('../../pages/information-center'));
const ChooseYourTribe = lazy(() => import('../../pages/choose-your-tribe'));
const Registration = lazy(() => import('../../pages/registration'));

export default function App() {
    const [scrollPosY, setScrollPosY] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [stop, setStop] = useState("");
    const app = useRef(null);
    const {name, version, os} = useMemo(() => {
        return detect()
    }, []);
    useEffect(() => {
        app.current && setScrollHeight(app.current.scrollHeight);
    }, [setScrollHeight, app]);
    const modifier = useModifier({name: "app", variation: [name, `${name}-${version} ${os}`]});
    const handleScroll = useCallback((e) => {
        setScrollPosY(e.target.scrollTop);
    }, [setScrollPosY]);
    const context = {
        scrollHeight,
        scrollPosY,
        playing,
        setPlaying,
        stop,
        setStop
    };

    return (
        <AppContext.Provider value={context}>
            <div ref={app} className={`${modifier} ${os.toLowerCase()}`} onScroll={handleScroll}>
                <Router>
                    <Suspense fallback={<Loading/>}>
                        <Switch>
                            <Route path="/" exact component={HomePage}/>
                            <Route path="/main-stage" exact component={MainStage}/>
                            <Route path="/info-center" exact component={InfoCenter}/>
                            <Route path="/choose-your-tribe" exact component={ChooseYourTribe}/>
                            <Route path="/registration" exact component={Registration}/>
                            <Route exact component={NotFound}/>
                        </Switch>
                    </Suspense>
                </Router>
            </div>
        </AppContext.Provider>
    )
}